import StackGrid, { transitions } from 'react-stack-grid';
import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

const transition = transitions.scaleDown;

const Archive = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 640px)',
  });
  const gridItemsRef = useRef([]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const images = shuffleArray([
    { src: '/images/nul1org_10th_1.jpeg' },
    { src: '/images/nul1org_10th_2.jpeg' },
    { src: '/images/nul1org_10th_3.jpeg' },
    { src: '/images/nul1org_10th_4.jpeg' },
    { src: '/images/nul1org_10th_5.jpeg' },
    { src: '/images/nul1org_10th_6.jpeg' },
    { src: '/images/nul1org_10th_7.jpeg' },
    { src: '/images/nul1org_10th_8.jpeg' },
    { src: '/images/nul1org_10th_9.jpeg' },

    { src: '/images/nul1org_9th_1.jpeg' },
    { src: '/images/nul1org_9th_2.jpeg' },
    { src: '/images/nul1org_9th_3.jpeg' },
    { src: '/images/nul1org_9th_4.jpeg' },
    { src: '/images/nul1org_9th_5.jpeg' },
    { src: '/images/nul1org_9th_6.jpeg' },
    { src: '/images/nul1org_9th_7.jpeg' },
    { src: '/images/nul1org_9th_8.jpeg' },
    { src: '/images/nul1org_9th_9.jpeg' },
    { src: '/images/nul1org_9th_10.jpeg' },

    { src: '/images/nul1org_8th_1.jpeg' },
    { src: '/images/nul1org_8th_2.jpeg' },
    { src: '/images/nul1org_8th_3.jpeg' },
    { src: '/images/nul1org_8th_4.jpeg' },
    { src: '/images/nul1org_8th_5.jpeg' },
    { src: '/images/nul1org_8th_6.jpeg' },
    { src: '/images/nul1org_8th_7.jpeg' },
    { src: '/images/nul1org_8th_8.jpeg' },
    { src: '/images/nul1org_8th_9.jpeg' },
    { src: '/images/nul1org_8th_10.jpeg' },

    { src: '/images/nul1org_7th_1.jpeg' },
    { src: '/images/nul1org_7th_2.jpeg' },
    { src: '/images/nul1org_7th_3.jpeg' },
    { src: '/images/nul1org_7th_4.jpeg' },
    { src: '/images/nul1org_7th_5.jpeg' },
    { src: '/images/nul1org_7th_6.jpeg' },
    { src: '/images/nul1org_7th_7.jpeg' },
    { src: '/images/nul1org_7th_8.jpeg' },
    { src: '/images/nul1org_7th_9.jpeg' },

    { src: '/images/nul1org_4th_1.jpeg' },
    { src: '/images/nul1org_4th_2.jpeg' },
    { src: '/images/nul1org_4th_3.jpeg' },
    { src: '/images/nul1org_4th_4.jpeg' },
    { src: '/images/nul1org_4th_5.jpeg' },
    { src: '/images/nul1org_4th_6.jpeg' },
    { src: '/images/nul1org_4th_7.jpeg' },

    { src: '/images/nul1org_11th_1.jpeg' },
    { src: '/images/nul1org_11th_2.jpeg' },
    { src: '/images/nul1org_11th_3.jpeg' },
    { src: '/images/nul1org_11th_4.jpeg' },
    { src: '/images/nul1org_11th_5.jpeg' },
    { src: '/images/nul1org_11th_6.jpeg' },
    { src: '/images/nul1org_11th_7.jpeg' },
    { src: '/images/nul1org_11th_8.jpeg' },
    { src: '/images/nul1org_11th_9.jpeg' },
    { src: '/images/nul1org_11th_10.jpeg' },

    { src: '/images/nul1org_12th_1.jpeg' },
    { src: '/images/nul1org_12th_2.jpeg' },
    { src: '/images/nul1org_12th_3.jpeg' },
    { src: '/images/nul1org_12th_4.jpeg' },
    { src: '/images/nul1org_12th_5.jpeg' },
    { src: '/images/nul1org_12th_6.jpeg' },
    { src: '/images/nul1org_12th_7.jpeg' },
    { src: '/images/nul1org_12th_8.jpeg' },
    { src: '/images/nul1org_12th_9.jpeg' },
    { src: '/images/nul1org_12th_10.jpeg' },

    { src: '/images/nul1org_13th_1.jpeg' },
    { src: '/images/nul1org_13th_2.jpeg' },
    { src: '/images/nul1org_13th_3.jpeg' },
    { src: '/images/nul1org_13th_4.jpeg' },
    { src: '/images/nul1org_13th_5.jpeg' },
    { src: '/images/nul1org_13th_6.jpeg' },
    { src: '/images/nul1org_13th_7.jpeg' },
    { src: '/images/nul1org_13th_8.jpeg' },

    { src: '/images/nul1org_14th_lookbook1.jpeg' },
    { src: '/images/nul1org_14th_lookbook2.jpeg' },
    { src: '/images/nul1org_14th_lookbook3.jpeg' },
    { src: '/images/nul1org_14th_lookbook4.jpeg' },
    { src: '/images/nul1org_14th_lookbook5.jpeg' },
    { src: '/images/nul1org_14th_lookbook6.jpeg' },
    { src: '/images/nul1org_14th_lookbook7.jpeg' },
    { src: '/images/nul1org_14th_lookbook8.jpeg' },
    { src: '/images/nul1org_14th_lookbook9.jpeg' },
    { src: '/images/nul1org_14th_lookbook10.jpeg' },

    { src: '/images/nul1org_15th_Lookbook1.jpeg' },
    { src: '/images/nul1org_15th_Lookbook2.jpeg' },
    { src: '/images/nul1org_15th_Lookbook3.jpeg' },
    { src: '/images/nul1org_15th_Lookbook4.jpeg' },
    { src: '/images/nul1org_15th_Lookbook5.jpeg' },
    { src: '/images/nul1org_15th_Lookbook6.jpeg' },
    { src: '/images/nul1org_15th_Lookbook7.jpeg' },
    { src: '/images/nul1org_15th_Lookbook8.jpeg' },
    { src: '/images/nul1org_15th_Lookbook9.jpeg' },
    { src: '/images/nul1org_15th_Lookbook10.jpeg' },

    { src: '/images/JohnnyTerror_Lookbook1.jpeg' },
    { src: '/images/JohnnyTerror_Lookbook2.jpeg' },
    { src: '/images/JohnnyTerror_Lookbook3.jpeg' },
    { src: '/images/JohnnyTerror_Lookbook4.jpeg' },
    { src: '/images/JohnnyTerror_Lookbook5.jpeg' },
    { src: '/images/JohnnyTerror_Lookbook6.jpeg' },
    { src: '/images/JohnnyTerror_Lookbook7.jpeg' },
    { src: '/images/JohnnyTerror_Lookbook8.jpeg' },
    { src: '/images/JohnnyTerror_Lookbook9.jpeg' },
    
    { src: '/images/nul1org_16th_Lookbook1.jpeg' },
    { src: '/images/nul1org_16th_Lookbook2.jpeg' },
    { src: '/images/nul1org_16th_Lookbook3.jpeg' },
    { src: '/images/nul1org_16th_Lookbook4.jpeg' },
    { src: '/images/nul1org_16th_Lookbook5.jpeg' },
    { src: '/images/nul1org_16th_Lookbook6.jpeg' },
    { src: '/images/nul1org_16th_Lookbook7.jpeg' },
    { src: '/images/nul1org_16th_Lookbook8.jpeg' },

  ])


  const getRandomSize = () => {
    const minSize = 150;
    const maxSize = 500;
    const size = Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;
    return size;
  };

  const handleImageLoad = (event) => {
    event.target.style.filter = 'none';
    event.target.style.transition = 'filter 0.3s ease';
  };

  const pixelatedStyle = {
    filter: 'blur(10px)',
    transition: 'filter 0.3s ease',
  };

  const getRandomAlignStyle = () => {
    const alignments = ['flex-start', 'center', 'flex-end'];
    const randomIndex = Math.floor(Math.random() * alignments.length);
    return alignments[randomIndex];
  };

  return (
    <div className="absolute top-0 left-0 flex flex-col justify-start w-screen h-screen overflow-x-hidden overflow-y-auto" style={{ backgroundColor: 'black' }}>
      <div className="mt-20">
        <StackGrid
          monitorImagesLoaded
          columnWidth={isDesktop ? 300 : '100%'}
          gutterWidth={10}
          gutterHeight={10}
          appear={transition.appear}
          appeared={transition.appeared}
          enter={transition.enter}
          entered={transition.entered}
          leaved={transition.leaved}
        >
          {images.map((obj, index) => (
            <figure
              ref={(el) => gridItemsRef.current.push(el)}
              key={index}
              className="grid_item"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: getRandomAlignStyle(),
                alignItems: getRandomAlignStyle(),
                width: '100%', // Make figure width 100% of its container
                height: getRandomSize(), // Set height to a random size
              }}
            >
              {obj.src ? (
                <img
                  key={obj.src}
                  src={obj.src}
                  alt="image"
                  loading="lazy"
                  style={{
                    ...pixelatedStyle,
                    maxWidth: '100%', // Ensure image takes full width on mobile
                    maxHeight: '100%',
                  }}
                  onLoad={handleImageLoad}
                />
              ) : (
                <div
                  style={{
                    width: obj.size,
                    height: obj.size,
                  }}
                ></div>
              )}
              <figcaption>{obj.label}</figcaption>
            </figure>
          ))}
        </StackGrid>
      </div>
    </div>
  );
};

export default Archive;