import { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useScroll } from '@react-three/drei';
import * as THREE from 'three';
import { extend } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import { Vector2 } from 'three';

// 创建自定义的ShaderMaterial
const SineWaveMaterial = shaderMaterial(
  { time: 0, map: null, repeat: new Vector2(1, 1) },
  // Vertex Shader
  `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `,
  // Fragment Shader
  `
  uniform float time;
  uniform sampler2D map;
  uniform vec2 repeat;
  varying vec2 vUv;

  void main() {
    vec2 uv = vUv;
    uv.y += sin(uv.x * 10.0 + time) * 0.1;
    uv *= repeat;
    vec4 color = texture2D(map, uv);
    gl_FragColor = color;
  }
  `
);

extend({ SineWaveMaterial });

function Banner(props) {
  const ref = useRef();
  const videoRef = useRef();

  useEffect(() => {
    const video = document.createElement('video');
    video.src = '/video.mp4'; // 更新为你的视频路径
    video.crossOrigin = 'Anonymous';
    video.loop = true;
    video.muted = true;
    video.style.display = 'none'; // 隐藏视频元素
    video.playsInline = true; // 确保在移动设备上不会全屏播放
    video.play();
    document.body.appendChild(video); // 将视频元素添加到DOM中
    videoRef.current = video;

    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.wrapS = videoTexture.wrapT = THREE.RepeatWrapping;
    ref.current.material.map = videoTexture;

    return () => {
      video.pause();
      document.body.removeChild(video); // 移除视频元素
    };
  }, []);

  const scroll = useScroll();

  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.material.time += delta;
    }
  });

  return (
    <mesh ref={ref} {...props}>
      <planeGeometry args={[10.2, 50, 128, 16]} /> {/* 调整宽度和高度参数 */}
      <sineWaveMaterial attach="material" map={null} repeat={[1, 1]} />
    </mesh>
  );
}

export default Banner;